import { sub, startOfDay, getUnixTime } from 'date-fns'

import makeRequest from 'src/js/utils/make-request'
import { isDev, PRICE_FEED_API_URL } from 'src/constants'

import DummyPriceData from './DummyPriceData.json'

const defaultFetchOptions = {
  method: 'GET',
  headers: { 'Content-Type': 'application/json', 'Cache-Control': 'max-age=60' },
}

export const fetchTickerData = async (assets, fiat = 'USD') => {
  let url = isDev ? `${PRICE_FEED_API_URL}/ticker` : '/price-feed-api/ticker'

  const parameters = new window.URLSearchParams()
  parameters.set('from', assets.join(','))
  parameters.set('to', fiat)
  parameters.set('ignoreInvalidSymbols', true)

  url = url.concat('?').concat(parameters.toString())

  const initialTickerData = await makeRequest(url, defaultFetchOptions)
  if (initialTickerData?.requestErrors?.invalidCryptoSymbols?.length > 0) {
    initialTickerData.requestErrors.invalidCryptoSymbols.forEach((invalidSymbol) => {
      delete initialTickerData[invalidSymbol]
    })

    const { requestErrors: _, ...tickerData } = initialTickerData
    return tickerData
  }

  return initialTickerData
}

export const fetchCurrentPrice = async (assets, fiat = 'USD') => {
  if (isDev) return DummyPriceData.current

  // USD is required in other parts of the application and must be included
  const to = fiat === 'USD' ? fiat : [fiat, 'USD']
  const parameters = new window.URLSearchParams()
  // in all queries
  parameters.set('from', assets)
  parameters.set('to', to)
  parameters.set('ignoreInvalidSymbols', true)

  let url = isDev ? `${PRICE_FEED_API_URL}/current-price` : '/price-feed-api/current-price'

  url = url.concat('?').concat(parameters.toString())

  const initialCurrentPriceData = await makeRequest(url, defaultFetchOptions)
  if (initialCurrentPriceData?.requestErrors?.invalidCryptoSymbols?.length > 0) {
    const { requestErrors: _, ...currentPrice } = initialCurrentPriceData
    return currentPrice
  }

  return initialCurrentPriceData
}

export const fetchHistoricalPrices = async ({
  assets,
  fiatArray = ['USD'],
  granularity,
  limit,
  timestamp,
}) => {
  if (isDev) return { data: DummyPriceData?.historical }

  const parameters = new window.URLSearchParams()
  parameters.set('from', assets.join())
  parameters.set('to', fiatArray.join())
  parameters.set('granularity', granularity)
  limit = limit || (granularity === 'day' ? 730 : 168)
  parameters.set('limit', limit)
  parameters.set(
    'timestamp',
    timestamp || getUnixTime(startOfDay(sub(new Date(), { [granularity]: 1 }), granularity))
  )
  parameters.set('ignoreInvalidSymbols', true)

  let url = isDev ? `${PRICE_FEED_API_URL}/historical-price` : '/price-feed-api/historical-price'

  url = url.concat('?').concat(parameters.toString())

  try {
    const initialHistoricalPricesData = await makeRequest(url, {
      ...defaultFetchOptions,
      headers: { 'Content-Type': 'application/json', 'Cache-Control': 'max-age=3600' },
    })

    if (initialHistoricalPricesData?.requestErrors?.invalidCryptoSymbols?.length > 0) {
      const { requestErrors: _, ...historicalPrices } = initialHistoricalPricesData
      return historicalPrices
    }

    return initialHistoricalPricesData
  } catch (e) {
    console.error('failed to fetch historical prices', e)
  }
}
